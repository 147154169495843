<template>
  <div id="mine">
    <div class="mine_top">
      <!-- 图片 -->
      <section class="mine_img">
        <h1>{{ title }}</h1>
        <p>{{ subhead }}</p>
      </section>
      <!-- 登录窗口 -->
      <section class="mine_con">
        <router-view></router-view>
      </section>
    </div>
    <!-- 备案链接 -->
    <div class="mine_bottom">
      <div>
         <a :href="href" target="_blank">{{ icp }}</a>
      </div>
      <div class="beian">
        <a :href="url" target="_blank">{{ beian }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "简家丨笔记",
      subhead: "我的生活、收纳、记录平台",
      href: "https://beian.miit.gov.cn/#/Integrated/index",
      url: "http://www.beian.gov.cn/portal/index.do",
      icp: "浙ICP备20011607号",
      beian: "浙公网安备 33010202002083号",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#mine {
  height: 100%;
  background-image: url(~@/assets/images/mine/mine_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.mine_top {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  width: 16.2rem;
  height: 6.8rem;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 4px 8px 16px rgba(156, 156, 156, 0.8);
}

.mine_img {
  width: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  letter-spacing: 0.05rem;
  background-image: url(~@/assets/images/mine/mine_img.png);
}

.mine_img > h1 {
  font-size: 0.18rem;
}

.mine_img > p {
  font-size: 0.16rem;
  letter-spacing: 0.05rem;
  margin: 0.1rem 0;
}

.mine_con {
  flex: 1;
  height: 100%;
  position: relative;
}

.mine_bottom {
  font-size: 0.16rem;
  position: absolute;
  bottom: 0.24rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beian{
  display: flex;
  align-items: center;
}

.beian:before {
  content: "";
  display: inline-block;
  height: 0.2rem;
  width: 0.2rem;
  margin-right: 0.05rem;
  background-image: url(~@/assets/images/mine/mine_beian.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.mine_bottom > a {
  color: #000;
}

.mine_bottom > a:visited {
  color: #000;
}
</style>